import React from "react";
import Layout from "./components/layout/Layout.jsx";
import AuthLayout from "./components/layout/Auth.jsx";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

const App = () => (
  <BrowserRouter>
    <Switch>
      <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
      <Route path="/admin" render={(props) => <Layout {...props} />} />
      <Redirect to="/auth/login" />
    </Switch>
  </BrowserRouter>
);

export default App