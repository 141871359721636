import {
  query,
  where,
  database,
  collection,
  getCountFromServer,
} from "../db/firebase";
import "./dashboard.css";
import Badge from "../badge/Badge";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import Table from "../components/table/Table";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ThemeAction from "../redux/actions/ThemeAction";
import StatusCard from "../components/status-card/StatusCard";

const chartOptions = {
  series: [
    {
      name: "Active Users",
      data: [40, 70, 10, 80, 36, 80, 46, 98, 72],
    },
    {
      name: "Active Builders",
      data: [49, 26, 53, 68, 34, 65, 13, 65],
    },
  ],
  options: {
    color: ["#6ab04c", "#2980b9"],
    chart: {
      background: "transparent",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
    legend: {
      position: "top",
    },
    grid: {
      show: false,
    },
  },
};

const topCustomers = {
  head: ["user", "total orders", "total spending"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const latestOrders = {
  header: ["order id", "user", "total price", "date", "status"],
  body: [
    {
      id: "#OD1711",
      user: "john doe",
      date: "17 Jun 2021",
      price: "$900",
      status: "shipping",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "pending",
    },
    {
      id: "#OD1712",
      user: "frank iva",
      date: "1 Jun 2021",
      price: "$400",
      status: "paid",
    },
    {
      id: "#OD1713",
      user: "anthony baker",
      date: "27 Jun 2021",
      price: "$200",
      status: "refund",
    },
  ],
};

const orderStatus = {
  shipping: "primary",
  pending: "warning",
  paid: "success",
  refund: "danger",
};

const renderOrderHead = (item, index) => {
  return <th key={index}>{item}</th>;
};
const renderOrderBody = (item, index) => {
  return (
    <tr key={index}>
      <td>{item.id}</td>
      <td>{item.user}</td>
      <td>{item.price}</td>
      <td>{item.date}</td>
      <td>
        <Badge type={orderStatus[item.status]} content={item.status} />
      </td>
    </tr>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const themeReducer = useSelector((state) => state.ThemeReducer.mode);

  const [usersCount, setUsersCount] = useState(0);
  const [buildersCount, setBuildersCount] = useState(0);
  const [homesphereUsersCount, setHomesphereUsersCount] = useState(0);
  const [homesphereBuildersCount, setHomesphereBuildersCount] = useState(0);

  useEffect(() => {
    dispatch(ThemeAction.getTheme());
  }, [dispatch]);

  useEffect(() => {
    const getCounts = async () => {
      let userRef = collection(database, "users");
      let builderRef = collection(database, "builders");

      // get user count
      getCountFromServer(userRef).then((snapshot) => {
        setUsersCount(snapshot.data().count);
      });

      // get builders count
      getCountFromServer(builderRef).then((snapshot) => {
        setBuildersCount(snapshot.data().count);
      });

      // get homesphere builders count
      getCountFromServer(
        query(builderRef, where("company", "==", "homesphere"))
      ).then((snapshot) => {
        setHomesphereBuildersCount(snapshot.data().count);
      });

      // get homesphere users count
      getCountFromServer(
        query(userRef, where("company", "==", "homesphere"))
      ).then((snapshot) => {
        setHomesphereUsersCount(snapshot.data().count);
      });
    };
    getCounts();
  }, []);

  return (
    <div>
      <h2 className="page-header" style={{ marginLeft: 20 }}>
        Dashboard
      </h2>
      <div className="row">
        <div className="col-6 fullWidthOnMobile">
          <div className="card full-height">
            <Chart
              options={
                themeReducer === "theme-mode-dark"
                  ? {
                      ...chartOptions.options,
                      theme: { mode: "dark" },
                    }
                  : {
                      ...chartOptions.options,
                      theme: { mode: "light" },
                    }
              }
              series={chartOptions.series}
              type="line"
              height="100%"
            />
          </div>
        </div>
        <div className="col-6 fullWidthOnMobile">
          <div className="row">
            <div className="col-6">
              <StatusCard
                icon="bx bx-user-pin"
                title="Total Users"
                count={usersCount}
              />
            </div>
            <div className="col-6">
              <StatusCard
                icon="bx bx-id-card"
                title="Total Builders"
                count={buildersCount}
              />
            </div>
            <div className="col-6">
              <StatusCard
                icon="bx bx-id-card"
                title="Homesphere Users"
                count={homesphereUsersCount}
              />
            </div>
            <div className="col-6">
              <StatusCard
                icon="bx bx-user-pin"
                title="Homesphere Builders"
                count={homesphereBuildersCount}
              />
            </div>
          </div>
        </div>
        <div className="col-4 fullWidthOnMobile">
          <div className="card">
            <div className="card__header">
              <h3>top customers</h3>
            </div>
            <div className="card__body">
              <Table
                headData={topCustomers.head}
                bodyData={topCustomers.body}
                renderHead={(item, index) => <th key={index}>{item}</th>}
                renderBody={(item, index) => (
                  <tr key={index}>
                    <td>{item.user}</td>
                    <td>{item.order}</td>
                    <td>{item.price}</td>
                  </tr>
                )}
              />
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
        <div className="col-8 fullWidthOnMobile">
          <div className="card">
            <div className="card__header">
              <h3>latest orders</h3>
            </div>
            <div className="card__body">
              <Table
                headData={latestOrders.header}
                renderHead={(item, index) => renderOrderHead(item, index)}
                bodyData={latestOrders.body}
                renderBody={(item, index) => renderOrderBody(item, index)}
              />
            </div>
            <div className="card__footer">
              <Link to="/">view all</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
