import "./layout.css";
import React from "react";
import Routes from "../Routes";
import TopNav from "../topnav/Topnav";
import Sidebar from "../sidebar/Sidebar";
import { useSelector } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";

const Layout = () => {
  const themeReducer = useSelector((state) => state.ThemeReducer);

  return (
    <BrowserRouter>
      <Route
        render={(props) => (
          <div className={`layout ${themeReducer.mode} ${themeReducer.color}`}>
            <Sidebar {...props} />
            <div className="layout__content">
              <TopNav {...props} />
              <div className="layout__content-main">
                <Routes />
              </div>
            </div>
          </div>
        )}
      />
    </BrowserRouter>
  );
};

export default Layout;
