import React, { useEffect } from "react";
import ThemeMenu from "../thememenu/ThemeMenu";
import { doc, getDoc, database } from "../../db/firebase";

const Topnav = (props) => {
  useEffect(() => {
    const getUserData = async () => {
      console.log("verifying admin account!");
      const uid = await localStorage.getItem("uid");
      const docSnap = await getDoc(doc(database, "admin", uid));
      if (docSnap.exists()) {
      } else {
        localStorage.removeItem("uid");
        props.history.replace("/auth/login");
        window.location.reload();
      }
    };

    getUserData();
  }, [props.history]);

  return (
    <div style={{ position: "absolute", width: "100%", left: 0 }}>
      <ThemeMenu />
    </div>
  );
};

export default Topnav;
