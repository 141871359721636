import {
  doc,
  auth,
  setDoc,
  database,
  createUserWithEmailAndPassword,
} from "../db/firebase";
import "./register.css";
import Swal from "sweetalert2";
import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export default function SignInSide() {
  const [role, setRole] = React.useState("");
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [showPsw, setShowPsw] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async () => {
    if (!email || !name || !password) {
      alert("Please fill all the fields");
    } else {
      setLoading(true);
      await createUserWithEmailAndPassword(auth, email, password)
        .then((user) => {
          setDoc(doc(database, "admin", user.user.uid), {
            createdAt: Date.now(),
            email,
            role,
            name,
          })
            .then(() => {
              setRole("");
              setName("");
              setEmail("");
              setPassword("");
              setLoading(false);
              Swal.fire(
                "Good job!",
                "Admin Successfully Registered!",
                "success"
              );
            })
            .catch((error) => {
              setLoading(false);
              Swal.fire("Something Went Wrong!", error, "error");
            });
        })
        .catch((error) => alert(error));
    }
  };

  const handleClickShowPassword = () => {
    setShowPsw(!showPsw);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const roles = [
    { value: "admin", label: "Admin" },
    { value: "subAdmin", label: "Sub-Admin" },
    { value: "viewer", label: "Viewer" },
  ];

  return (
    <div className="col-12">
      <h2 className="page-header">Create Admin Account</h2>
      <div className="card">
        <Box
          sx={{
            mb: 4,
            mx: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <TextField
            fullWidth
            autoFocus
            name="name"
            value={name}
            margin="normal"
            variant="outlined"
            label="Full Name"
            className="formInput"
            autoComplete="name"
            style={{ marginBottom: "20px" }}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            fullWidth
            autoFocus
            name="email"
            value={email}
            margin="normal"
            variant="outlined"
            placeholder="Email Address"
            className="formInput"
            autoComplete="email"
            style={{ marginBottom: "20px" }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            select
            fullWidth
            autoFocus
            name="email"
            value={role}
            margin="normal"
            variant="outlined"
            placeholder="Select Role"
            className="formInput"
            autoComplete="email"
            SelectProps={{ native: true }}
            style={{ marginBottom: "20px" }}
            onChange={(e) => setRole(e.target.value)}
          >
            {roles.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </TextField>
          <TextField
            fullWidth
            type="password"
            name="password"
            margin="normal"
            placeholder="Password"
            value={password}
            variant="outlined"
            className="formInput"
            autoComplete="current-password"
            style={{ marginBottom: "20px" }}
            onChange={(e) => setPassword(e.target.value)}
            endadornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPsw ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          <Button
            fullWidth
            type="submit"
            loading={loading ? loading.toString() : undefined} // change here
            variant="contained"
            onClick={handleSubmit}
            style={{
              color: "white",
              height: "50px",
              marginTop: "15px",
              borderRadius: "3px",
              backgroundColor: "#349eff",
            }}
          >
            Create Account
          </Button>
        </Box>
      </div>
    </div>
  );
}
