import {
  doc,
  addDoc,
  getDocs,
  database,
  deleteDoc,
  collection,
} from "../db/firebase";
import {
  Chip,
  Button,
  ImageList,
  TextField,
  Typography,
  IconButton,
  ListSubheader,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import "./register.css";
import Swal from "sweetalert2";
import * as React from "react";
import InfoIcon from "@mui/icons-material/Delete";

const ManageServices = () => {
  const [title, setTitle] = React.useState("");
  const [image, setImage] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [services, setServices] = React.useState([]);
  const [categoryTitle, setCategoryTitle] = React.useState("");
  const [categoryImage, setCategoryImage] = React.useState("");
  const [serviceCategories, setServiceCategories] = React.useState([]);

  React.useEffect(() => {
    const arr = [];
    const array = [];
    getDocs(collection(database, "services")).then((docSnap) => {
      docSnap.forEach((doc) => array.push({ ...doc.data(), id: doc.id }));
      setServices(array);
    });
    getDocs(collection(database, "serviceCategories")).then((docSnap) => {
      docSnap.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
      setServiceCategories(arr);
    });
  }, []);

  const handleSubmit = async () => {
    if (!title || !image || !category) {
      alert("Please fill all the fields");
    } else {
      let obj = { title, category, image };
      setServices((prev) => [...prev, obj]);
      addDoc(collection(database, "services"), obj)
        .then(() => {
          setTitle("");
          setImage("");
          setCategory("");
          Swal.fire("Good job!", "Service Added Successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Something Went Wrong!", error, "error");
        });
    }
  };

  const handleCategorySubmit = async () => {
    if (!categoryTitle || !categoryImage) {
      alert("Category Title and Image are required");
    } else {
      let obj = { title: categoryTitle, image: categoryImage };
      setServiceCategories((prev) => [...prev, obj]);
      addDoc(collection(database, "serviceCategories"), obj)
        .then(() => {
          setCategoryTitle("");
          setCategoryImage("");
          Swal.fire(
            "Good job!",
            "Service Category Added Successfully!",
            "success"
          );
        })
        .catch((error) => {
          Swal.fire("Something Went Wrong!", error, "error");
        });
    }
  };

  const handleDelete = async () => {
    alert("Are you sure you want to delete this service?");
  };

  const handleDeleteCard = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let servicesArray = services.filter((el) => el.id !== item.id);
        setServices(servicesArray);
        deleteDoc(doc(database, "services", item.id)).then(() => {
          Swal.fire("Deleted!", "Your file has been deleted.", "success");
        });
      }
    });
  };

  return (
    <div className="col-12">
      <h2 className="page-header">Manage Services</h2>
      <div className="card col-12">
        <Typography
          variant="h4"
          style={{
            fontSize: "22px",
            fontWeight: "500",
            textAlign: "center",
          }}
        >
          Add New Category
        </Typography>

        <div style={{ display: "flex", alignItems: "center" }}>
          <TextField
            fullWidth
            margin="normal"
            variant="outlined"
            value={categoryTitle}
            label="Service Category"
            onChange={(e) => setCategoryTitle(e.target.value)}
          />

          <Button
            type="submit"
            variant="contained"
            onClick={handleCategorySubmit}
            style={{
              color: "white",
              height: "55px",
              width: "200px",
              marginTop: "5px",
              marginLeft: "10px",
              borderRadius: "3px",
              backgroundColor: "#349eff",
            }}
          >
            Add Category
          </Button>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="card col-12">
          <Typography
            variant="h4"
            style={{
              fontSize: "22px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Add New Service
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              fullWidth
              value={title}
              margin="normal"
              variant="outlined"
              className="formInput"
              label="Service Item Name"
              onChange={(e) => setTitle(e.target.value)}
            />
            <TextField
              fullWidth
              value={image}
              margin="normal"
              variant="outlined"
              className="formInput"
              style={{ marginLeft: "10px" }}
              label="Service Logo URL (cloudinary)"
              onChange={(e) => setImage(e.target.value)}
            />
            <TextField
              select
              fullWidth
              margin="normal"
              value={category}
              variant="outlined"
              className="formInput"
              label="Select Category"
              style={{ marginLeft: "10px" }}
              SelectProps={{ native: true }}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select Category</option>
              {serviceCategories.length &&
                serviceCategories.map((option, index) => (
                  <option key={index} value={option.title}>
                    {option.title}
                  </option>
                ))}
            </TextField>
          </div>

          <Button
            fullWidth
            type="submit"
            variant="contained"
            onClick={handleSubmit}
            style={{
              color: "white",
              height: "50px",
              borderRadius: "3px",
              backgroundColor: "#349eff",
            }}
          >
            Add Service
          </Button>
        </div>
      </div>

      {!!serviceCategories.length && (
        <div className="card">
          <Typography
            variant="h4"
            style={{
              fontSize: "20px",
              fontWeight: "500",
              marginBottom: "20px",
            }}
          >
            Services
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            {serviceCategories.map((item, index) => (
              <Chip
                key={index}
                label={item.title}
                variant="outlined"
                onDelete={handleDelete}
                style={{ marginRight: "15px" }}
              />
            ))}
          </div>
        </div>
      )}
      {!!services.length ? (
        serviceCategories.map((ele, index) => {
          let packagesArray = services.filter(
            (el) => el.category === ele.title
          );
          return (
            <ImageList
              gap={10}
              cols={5}
              key={index}
              sx={{ marginTop: "30px", padding: "5px" }}
            >
              <ImageListItem key="Subheader" cols={5}>
                <ListSubheader component="div">{ele.title}</ListSubheader>
              </ImageListItem>
              {!!packagesArray.length ? (
                packagesArray.map((item, index) => {
                  return (
                    <ImageListItem
                      key={index}
                      sx={{ width: "100%", border: "1px solid gainsboro" }}
                    >
                      <div
                        style={{
                          height: "180px",
                          paddingTop: "20px",
                          textAlign: "center",
                        }}
                      >
                        <img
                          loading="lazy"
                          src={item.image}
                          alt={item.title}
                          srcSet={item.image}
                          style={{ width: "50%", objectFit: "contain" }}
                        />
                      </div>
                      <ImageListItemBar
                        title={item.title}
                        subtitle={item.category}
                        actionIcon={
                          <IconButton
                            onClick={() => handleDeleteCard(item)}
                            aria-label={`info about ${item.title}`}
                            sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                          >
                            <InfoIcon />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  );
                })
              ) : (
                <div
                  style={{
                    width: "400%",
                    padding: "20px",
                    textAlign: "center",
                  }}
                >
                  <h1 className="text">No {ele.title} Services Found!</h1>
                </div>
              )}
            </ImageList>
          );
        })
      ) : (
        <div
          style={{
            height: "100px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text">No Services Found!</h1>
        </div>
      )}
    </div>
  );
};

export default ManageServices;
