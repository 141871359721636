import {
  query,
  where,
  database,
  collection,
  getCountFromServer,
} from "../../db/firebase";
import "./sidebar.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/Hizzy-admin.png";
import sidebar_items from "../../assets/JsonData/sidebar_routes.json";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";
  return (
    <div
      className="sidebar_item"
      onClick={() => {
        if (props.title === "Chat") {
          props.setCount(0);
        }
      }}
    >
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
        {props.title === "Chat" && props.count > 0 && (
          <div className="sidebar__item-badge">
            <span>{props.count}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const Sidebar = (props) => {
  const [count, setCount] = useState(0);

  const activeItem = sidebar_items.findIndex(
    (item) => item.route === props.location.pathname
  );

  useEffect(() => {
    const getChatUsers = async () => {
      // get user count
      getCountFromServer(
        query(
          collection(database, "users"),
          where("startedChat", "==", true),
          where("unread", "==", true)
        )
      ).then((snapshot) => {
        setCount(snapshot.data().count);
      });
    };
    getChatUsers();
  }, []);

  const logout = () => {
    localStorage.removeItem("uid");
    props.history.replace("/auth/login");
    window.location.reload();
  };

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img src={logo} alt="company logo" />
      </div>
      {sidebar_items.map((item, index) => (
        <Link to={item.route} key={index}>
          <SidebarItem
            count={count}
            icon={item.icon}
            setCount={setCount}
            title={item.display_name}
            active={index === activeItem}
          />
        </Link>
      ))}

      <div onClick={logout} style={{ cursor: "pointer" }}>
        <SidebarItem icon="bx bx-log-out" title="Logout" />
      </div>
    </div>
  );
};

export default Sidebar;
