import React from "react";
import Login from "../../pages/Login";
import { Route, Switch, Redirect } from "react-router-dom";

export default function Auth() {
  return (
    <Switch style={{ width: "100%" }}>
      <Route path="/auth/login" component={Login} />
      <Redirect to="/auth/login" />
    </Switch>
  );
}
