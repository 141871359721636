import React from "react";
import Map from "../pages/map";
import Chat from "../pages/Chat";
import Users from "../pages/Users";
import Invite from "../pages/Invite";
import Register from "../pages/Register";
import Settings from "../pages/Settings";
import Builders from "../pages/Builders";
import Dashboard from "../pages/Dashboard";
import Homesphere from "../pages/Homesphere";
import ErrorScreen from "../pages/ErrorScreen";
import { Route, Switch } from "react-router-dom";
import Notifications from "../pages/Notifications";
import ManageService from "../pages/ManageService";
import ManagePackages from "../pages/ManagePackages";

const Routes = () => (
  <Switch>
    <Route path="/admin/manage-packages" component={ManagePackages} />
    <Route path="/admin/manage-service" component={ManageService} />
    <Route path="/admin/Notifications" component={Notifications} />
    <Route path="/admin/dashboard" exact component={Dashboard} />
    <Route path="/admin/homesphere" component={Homesphere} />
    <Route path="/admin/invite-users" component={Invite} />
    <Route path="/admin/new-member" component={Register} />
    <Route path="/admin/builders" component={Builders} />
    <Route path="/admin/settings" component={Settings} />
    <Route path="/admin/users" component={Users} />
    <Route path="/admin/chat" component={Chat} />
    <Route path="/admin/map" component={Map} />
    <Route path="*" component={ErrorScreen} />
  </Switch>
);

export default Routes;
