import {
  Box,
  Grid,
  List,
  Modal,
  Button,
  ListItem,
  TextField,
  Typography,
  ListItemText,
  ListSubheader,
} from "@mui/material";
import "./register.css";
import Swal from "sweetalert2";
import * as React from "react";
import { addDoc, database, collection, getDocs } from "../db/firebase";

const style = {
  p: 4,
  width: 500,
  top: "50%",
  left: "50%",
  boxShadow: 24,
  position: "absolute",
  bgcolor: "background.paper",
  transform: "translate(-50%, -50%)",
};

const ManageServices = () => {
  const [title, setTitle] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [packages, setPackages] = React.useState([]);
  const [category, setCategory] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [description, setDescription] = React.useState("");

  React.useEffect(() => {
    const arr = [];
    const array = [];
    getDocs(collection(database, "serviceCategories")).then((docSnap) => {
      docSnap.forEach((doc) => array.push({ ...doc.data(), id: doc.id }));
      setCategories(array);
    });
    getDocs(collection(database, "packages")).then((docSnap) => {
      docSnap.forEach((doc) => arr.push({ ...doc.data(), id: doc.id }));
      setPackages(arr);
    });
  }, []);

  const handleSubmit = async () => {
    if (!title) {
      alert("Please fill all the fields");
    } else {
      setLoading(true);
      let obj = { price, title, category, description };
      setPackages([...packages, obj]);
      addDoc(collection(database, "packages"), obj)
        .then(() => {
          setTitle("");
          setPrice("");
          setOpen(false);
          setLoading(false);
          setDescription("");
          Swal.fire("Good job!", "Package Added Successfully!", "success");
        })
        .catch((error) => {
          setLoading(false);
          Swal.fire("Something Went Wrong!", error, "error");
        });
    }
  };

  const addPackage = () => {
    setOpen(true);
  };
 
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="col-12">
      <h2 className="page-header">Manage Services & Packages</h2>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "10px",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h4"
          style={{ fontSize: "25px", fontWeight: "bold" }}
        >
          Service Packages
        </Typography>
        <Button
          type="submit"
          variant="contained"
          onClick={addPackage}
          style={{
            color: "white",
            height: "50px",
            padding: "0 30px",
            fontWeight: "bold",
            borderRadius: "3px",
            backgroundColor: "#349eff",
          }}
        >
          Add Package
        </Button>
      </div>
      {!!packages.length ? (
        categories.map((item) => {
          let packagesArray = packages.filter(
            (el) => el.category === item.title
          );
          return (
            <List
              key={item.title}
              sx={{
                width: "100%",
                padding: "20px",
                borderRadius: 2,
                marginTop: "20px",
                bgcolor: "background.paper",
                boxShadow: "0 0 12px -5px gray",
              }}
              subheader={<ListSubheader>{item.title}</ListSubheader>}
            >
              {packagesArray.map((item, index) => (
                <ListItem
                  key={item.id}
                  style={{
                    borderBottom:
                      packagesArray.length - 1 !== index
                        ? "1px solid gainsboro"
                        : "none",
                  }}
                >
                  <ListItemText
                    primary={item.title}
                    secondary={item.description}
                  />
                </ListItem>
              ))}
            </List>
          );
        })
      ) : (
        <div
          style={{
            height: "60vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 className="text">No Packages Found!</h1>
        </div>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box id="modal-popup" sx={style}>
          <Grid style={{ width: "100%" }}>
            <Typography
              variant="h4"
              style={{
                fontSize: "22px",
                fontWeight: "500",
                textAlign: "center",
              }}
            >
              Add New Package
            </Typography>
            <br />
            <br />
            <Box sx={{ mx: 1, my: 4 }}>
              <TextField
                fullWidth
                autoFocus
                title="title"
                value={title}
                label="Title"
                margin="normal"
                variant="outlined"
                className="formInput"
                autoComplete="title"
                onChange={(e) => setTitle(e.target.value)}
              />
              <br />
              <br />
              <TextField
                fullWidth
                autoFocus
                title="price"
                label="Price"
                value={price}
                margin="normal"
                variant="outlined"
                autoComplete="price"
                className="formInput"
                onChange={(e) => setPrice(e.target.value)}
              />
              <br />
              <br />
              <TextField
                fullWidth
                autoFocus
                margin="normal"
                variant="outlined"
                title="description"
                value={description}
                label="Description"
                className="formInput"
                autoComplete="description"
                onChange={(e) => setDescription(e.target.value)}
              />
              <br />
              <br />
              <TextField
                select
                fullWidth
                autoFocus
                name="email"
                margin="normal"
                value={category}
                variant="outlined"
                label="Select Role"
                className="formInput"
                autoComplete="email"
                SelectProps={{ native: true }}
                style={{ marginBottom: "20px" }}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Select Package</option>
                {categories.length &&
                  categories.map((option) => (
                    <option key={option.title} value={option.title}>
                      {option.title}
                    </option>
                  ))}
              </TextField>
              <br />
              <br />
              <Button
                fullWidth
                type="submit"
                loading={loading}
                disabled={loading}
                variant="contained"
                onClick={handleSubmit}
                style={{
                  color: "white",
                  height: "50px",
                  borderRadius: "3px",
                  backgroundColor: "#349eff",
                }}
              >
                Add Package
              </Button>
            </Box>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
};

export default ManageServices;
// {
//    {packages.map((item, index) => (
//             <ListItem
//               key={index}
//               alignItems="flex-start"
//               style={{
//                 borderBottom:
//                   packages.length - 1 !== index
//                     ? "1px solid gainsboro"
//                     : "none",
//               }}
//             >
//               <ListItemText
//                 primary={
//                   <div
//                     style={{
//                       display: "flex",
//                       marginBottom: "5px",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Typography
//                       variant="body1"
//                       component="span"
//                       color="text.primary"
//                     >
//                       {`${item.title} ${item.price && `- $${item.price}`}`}
//                     </Typography>
//                     <Chip label={item.category} variant="outlined" />
//                   </div>
//                 }
//                 secondary={
//                   <div
//                     style={{
//                       display: "flex",
//                       marginBottom: "5px",
//                       alignItems: "center",
//                       justifyContent: "space-between",
//                     }}
//                   >
//                     <Typography
//                       variant="body1"
//                       component="span"
//                       color="text.primary"
//                     >
//                       {item.description}
//                     </Typography>
//                   </div>
//                 }
//               />
//             </ListItem>
//           ))}
// }
